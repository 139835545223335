<template>
	<div id="404">
		<header>
			<div class="container">
				<router-link to="/">
					<img src="@/assets/terminal.svg" alt="" />
				</router-link>
			</div>
		</header>

		<main class="container">
			<div class="text-center">
				<i data-v-19fb68dd="" class="fas fa-unlink"></i>

				<h1 class="mb-20">Leinkjan er óvirkin</h1>

				<p>
					Leinkjan, sum tú roynir at vitja, er óvirkin. Tað kann merkja, at terminal.fo hevur óvirkað hana, ella at URL'ið er skeivt. Um tú heldur, leinkjan burdi verið virkin, kanst tú seta teg í samband við okkum hjá terminal.fo, og so kunnu
					vit royna at loysa trupuleikan saman.
				</p>

				<a href="mailto:terminal@terminal.fo" class="btn mt-30">Send okkum ein teldupost</a>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: 'Client404',

	components: {},
};
</script>

<style lang="scss" scoped>
i {
	font-size: 32px;
	margin-bottom: 24px;
}

p {
	max-width: 640px;
	margin: 0 auto;
	font-size: 15px;
	line-height: 1.5;
}

.btn {
	display: inline-block;
}
</style>
